/**
 * @author Alan Saucedo
 * @company Erillam Healthcare
 * @version 1.0
 */

import axios from "axios";
import { ServicePath } from "@/core";
import { SECURE_ENDPOINT_REPORTS } from "@/core/AppConfiguration";
import { instance } from "@/core/ApiConfig.js";
import Vue from "vue";


const EXCEL_STOCK_WAREHOUSE = SECURE_ENDPOINT_REPORTS + "exsp/exswa";
const EXCEL_STOCK_MOVEMENT = SECURE_ENDPOINT_REPORTS + "exsp/exsmv";

const EXCEL_OUTPUT_INCIDENT = SECURE_ENDPOINT_REPORTS + "exsp/exsin";
const EXCEL_OUTPUT_CONSUMPTION_KEY = SECURE_ENDPOINT_REPORTS + "exsp/exock";

const EXCEL_AUDIT_OUTPUT = SECURE_ENDPOINT_REPORTS + "exsp/exaor";
const EXCEL_PHATMACY_JOB_TIME = SECURE_ENDPOINT_REPORTS + "exsp/expjt";

const EXCEL_INVENTORY_OPD = SECURE_ENDPOINT_REPORTS + "exsvc/ex122";
const EXCEL_INVENTORY_PHARMACY = SECURE_ENDPOINT_REPORTS + "exsvc/ex123";
const EXCEL_REMISSION_SUPPLIER_OPD = SECURE_ENDPOINT_REPORTS + "exsvc/ex124";
const EXCEL_REMISSION_TRANSFER_OPD = SECURE_ENDPOINT_REPORTS + "exsvc/ex125";
const EXCEL_INVENTEXCEL_SHIPMENTS_AND_PRODUCTS_OPD =
  SECURE_ENDPOINT_REPORTS + "exsvc/ex126";

const REPORT_AUDIT = "audit/reports";
const responseTypeV = { responseType: "blob" };

export default class ExcelRequest {


  /* TRAZABILIDAD - OPD */
  static async supplier_entries_opd(data) {
    return await axios.post(EXCEL_REMISSION_SUPPLIER_OPD, data);
  }

  static async inventory_cedis_opd(data) {
    return await axios.post(EXCEL_INVENTORY_OPD, data, responseTypeV);
  }

  static async inventory_pharmacy_opd(data) {
    return await axios.post(EXCEL_INVENTORY_PHARMACY, data);
  }

  static async transfer_entries_opd(data) {
    return await axios.post(EXCEL_REMISSION_TRANSFER_OPD, data);
  }

  static async outgoing_opd(data) {
    return await axios.post(
      EXCEL_INVENTEXCEL_SHIPMENTS_AND_PRODUCTS_OPD,
      data,
      responseTypeV
    );
  }


  static download64(response, fileName) {
    console.log(response.data);
    const url = URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.ms-excel",
      })
    );
    
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    console.log(url);
    downloadLink.download = fileName;
    downloadLink.click();
  }

  static download(response, fileName) {
    const url = URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.ms-excel",
      })
    );

    /* Simulo el click */
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }

  /* Genera el archivo */
  static async downloadBase64(response, fileName) {
    const linkSource = `data:application/vnd.ms-excel;base64,${response.entity}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  static reportOrders(data) {
    return axios
      .post(ServicePath.EXCEL_ORDERS, data, responseTypeV)
      .then((response) => {
        this.download(response, "ordenesSalida.xlsx");
      });
  }

  static reportShipmentsDetails(data) {
    return axios
      .post(ServicePath.EXCEL_ORDER_DETAIL, data, responseTypeV)
      .then((response) => {
        this.download(response, "detalleOrden.xlsx");
      });
  }

  static reportOrdersDispensing(data) {
    return axios.post(
      ServicePath.EXCEL_ORDERS_DISPENSING,
      data,
      responseTypeV
    );
  }

  static reportOrdersDecrease(data) {
    return axios
      .post(ServicePath.EXCEL_ORDERS_DECREASE, data, responseTypeV)
      .then((response) => {
        this.download(response, "salidas_motivo.xlsx");
      });
  }

  static reportShipmentsTransit(data) {
    return axios
      .post(ServicePath.EXCEL_SHIPMENTS_TRANSIT, data, responseTypeV)
      .then((response) => {
        this.download(response, "envios_en_transito.xlsx");
      });
  }

  static reportOrdersHistoricalPicking(data) {
    return axios
      .post(
        ServicePath.EXCEL_ORDERS_HISTORICAL_PICKING,
        data,
        responseTypeV
      )
      .then((response) => {
        this.download(response, "historialPicking.xlsx");
      });
  }

  static reportOrdersMovementHistory(data) {
    return axios
      .post(ServicePath.EXCEL_ORDERS_MOVEMENT_HISTORY, data, responseTypeV)
      .then((response) => {
        this.download(response, "movimientoPicking.xlsx");
      });
  }

  static reportShipments(data) {
    return axios
      .post(ServicePath.EXCEL_SHIPMENTS, data, responseTypeV)
      .then((response) => {
        this.download(response, "historico_envios.xlsx");
      });
  }

  static reportBranchOffice(data) {
    return axios
      .post(ServicePath.EXCEL_BRANCH_OFFICE, data, responseTypeV)
      .then((response) => {
        this.download(response, "sucursales.xlsx");
      });
  }

  static reportUsers(data) {
    return axios
      .post(ServicePath.EXCEL_USERS, data, responseTypeV)
      .then((response) => {
        this.download(response, "usuario.xlsx");
      });
  }

  static reportBiddings(data) {
    return axios
      .post(ServicePath.EXCEL_BIDDINGS, data, responseTypeV)
      .then((response) => {
        this.download(response, "licitaciones.xlsx");
      });
  }

  static reportWareHouse(data) {
    return axios
      .post(ServicePath.EXCEL_WAREHOUSE, data, responseTypeV)
      .then((response) => {
        this.download(response, "almacen.xlsx");
      });
  }

  /* Categorias */
  static reportDoctor(data) {
    return axios
      .post(ServicePath.EXCEL_DOCTOR, data, responseTypeV)
      .then((response) => {
        this.download(response, "doctores.xlsx");
      });
  }

  static reportProducts(data) {
    return axios
      .post(ServicePath.EXCEL_PRODUCTO, data, responseTypeV)
      .then((response) => {
        this.download(response, "productos.xlsx");
      });
  }

  static reportjurisdictions(data) {
    return axios
      .post(ServicePath.EXCEL_JURISDICTIONS, data, responseTypeV)
      .then((response) => {
        this.download(response, "jurisdiccion.xlsx");
      });
  }

  static reportFiscalFound(data) {
    return axios
      .post(ServicePath.EXCEL_FISCAL_FOUND, data, responseTypeV)
      .then((response) => {
        this.download(response, "fuenteFinanciamiento.xlsx");
      });
  }

  static reportCategory(data) {
    return axios
      .post(ServicePath.EXCEL_CATEGORY, data, responseTypeV)
      .then((response) => {
        this.download(response, "categorias.xlsx");
      });
  }

  static reportRole(data) {
    return axios
      .post(ServicePath.EXCEL_ROLE, data, responseTypeV)
      .then((response) => {
        this.download(response, "roles.xlsx");
      });
  }

  static reportSupplier(data) {
    return axios
      .post(ServicePath.EXCEL_SUPPLIERS, data, responseTypeV)
      .then((response) => {
        this.download(response, "proveedor.xlsx");
      });
  }
  /*Category*/

  static reportOrderDecreaseDetail(data) {
    return axios
      .post(ServicePath.EXCEL_ORDERS_DECREASE_DETAIL, data, responseTypeV)
      .then((response) => {
        this.download(response, "mermaDetalle.xlsx");
      });
  }

  static reportRemmisions(data) {
    return axios
      .post(ServicePath.EXCEL_REMMISIONS, data, responseTypeV)
      .then((response) => {
        this.download(response, "entradas.xlsx");
      });
  }

  static reportRemmisionsOrder(data) {
    return axios
      .post(ServicePath.EXCEL_REMMISIONS_ORDERS, data, responseTypeV)
      .then((response) => {
        this.download(response, "pedidos.xlsx");
      });
  }

  static reportReceptionPending(data) {
    return axios
      .post(ServicePath.EXCEL_RECEPTION_PENDINGS, data, responseTypeV)
      .then((response) => {
        this.download(response, "recepcionesPendientes.xlsx");
      });
  }

  static reportReceptionHistory(data) {
    return axios
      .post(ServicePath.EXCEL_RECEPTION_HISTORY, data, responseTypeV)
      .then((response) => {
        this.download(response, "recepcionesHistorico.xlsx");
      });
  }

  static reportIncidence(data) {
    return axios
      .post(ServicePath.EXCEL_INCIDENCE, data, responseTypeV)
      .then((response) => {
        this.download(response, "incidenciasRealizadas.xlsx");
      });
  }

  static reportIncidenceIn(data) {
    return axios
      .post(ServicePath.EXCEL_INCIDENCE_IN, data, responseTypeV)
      .then((response) => {
        this.download(response, "incidenciasEntrantes.xlsx");
      });
  }

  static reportOrderPdf(data) {
    return axios
      .post(ServicePath.REPORT_STARTING_ORDER, data, responseTypeV)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );

        window.open(url);
      });
  }

  static reportOrderPdfTemp(data) {
    return axios
      .post(ServicePath.REPORT_STARTING_ORDER_TEMP, data, responseTypeV)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );

        window.open(url);
      });
  }
  static reportDecreasePdf(data) {
    return axios
      .post(ServicePath.REPORT_DECREASE, data, responseTypeV)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );

        window.open(url);
      });
  }
  static reportReceptionPdf(data) {
    return axios
      .post(ServicePath.REPORT_RECEPTION, data, responseTypeV)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );

        window.open(url);
      });
  }
  static downloadPDFExistence(data) {
    return axios.post(ServicePath.REPORT_EXISTENCE, data, responseTypeV);
  }
  static reportRemmisionPdf(data) {
    return axios
      .post(ServicePath.REPORT_PRODUCT_ENTRY, data, responseTypeV)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );

        window.open(url);
      });
  }
  static reportExpired(data) {
    return axios.post(ServicePath.REPORT_EXPIRED, data, responseTypeV);
  }
  static reportTraceabilityProduct(data) {
    return axios.post(ServicePath.EXCEL_CARDEX, data, responseTypeV);
  }
  static reportTraceabilityProductLot(data) {    
    return axios.post(ServicePath.EXCEL_CARDEX_LOT, data, responseTypeV);
  }
  static reportTraceabilityProductStock(data) {
    return axios.post(ServicePath.EXCEL_CARDEX_STOCK, data, responseTypeV);
  }
  static reportStockObservation(data) {
    return axios.post(
      ServicePath.EXCEL_STOCK_OBSERVATION,
      data,
      responseTypeV
    );
  }
  static reportPrescription(data) {
    return axios
      .post(ServicePath.REPORT_PRESCRIPTION, data, responseTypeV)
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );

        window.open(url);
      });
  }
  static reportPrescriptionIntrahospital(data) {
    return axios
      .post(
        ServicePath.REPORT_PRESCRIPTION_INTRAHOSPITAL,
        data,
        responseTypeV
      )
      .then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );

        window.open(url);
      });
  }
  /* Movimientos y existencias */
  static reportEntriesBySupplier(data) {
    return axios.post(
      ServicePath.EXCEL_ENTRIES_BY_SUPPLIER,
      data,
      responseTypeV
    );
  }
  static reportRemissionSuplier(data) {
    return axios.post(ServicePath.EXCEL_REMISSION_SUPPLIER, data);
  }
  // Entradas por Traspaso
  static reportRemissionSuplierTransfer(data) {
    return axios.post(ServicePath.EXCEL_REMISSION_SUPPLIER_TRASFER, data);
  }

  static dispensingConsolidated() {
    return axios.post(ServicePath.EXCEL_DISPENSING_CONSILIDATED);
  }

  static dispensingConsolidatedNegate() {
    return axios.post(ServicePath.EXCEL_DISPENSING_CONSILIDATED_NEGATE);
  }

  static reportCostsPerWarehouse(data) {
    return axios.post(
      ServicePath.EXCEL_COSTS_PER_WAREHOUSE,
      data,
      responseTypeV
    );
  }

  static reportMovementsInventoryStock(data) {
    return axios
      .post(
        ServicePath.EXCEL_MOVEMENTS_INVENTORY_STOCK,
        data,
        responseTypeV
      )
      .then((response) => {
        this.download(response, "movimientoInventarioExistencias.xlsx");
      });
  }

  static reportMedicalPrescriptionByDate(data) {
    return axios.post(ServicePath.EXCEL_MEDICAL_PRESCRIPTION_BY_DATE, data);
  }

  static reportMedicalPrescriptionIntrahospitalByDate(data) {
    return axios.post(
      ServicePath.EXCEL_MEDICAL_PRESCRIPTION_INTRAHOSPITAL_BY_DATE,
      data
    );
  }
  /**EXCEL_CHECKIN_AND_CHECKOUT */
  static reportCheckoutAndCheckIn(data) {
    return axios.post(ServicePath.EXCEL_CHECKIN_AND_CHECKOUT, data);
  }

  /* Dashboard */
  static dashboard(path, sucursal) {
    return axios.post(path, responseTypeV).then((response) => {
      this.download(response, sucursal + ".xlsx");
    });
  }

  static reportDashboardBranchOffice(data) {
    return axios
      .post(ServicePath.EXCEL_DASHBOARD_BRANCH_OFFICE, data, responseTypeV)
      .then((response) => {
        this.download(response, "porcentajeSucursal.xlsx");
      });
  }

  static reportTransfer(data) {
    return axios
      .post(ServicePath.EXCEL_TRANFER, data, responseTypeV)
      .then((response) => {
        this.download(response, "transferencias.xlsx");
      });
  }

  static reportReturn(data) {
    return axios
      .post(ServicePath.EXCEL_RETURN, data, responseTypeV)
      .then((response) => {
        this.download(response, "devoluciones.xlsx");
      });
  }

  static reportInventoryInStore(data) {
    return axios.post(
      ServicePath.EXCEL_MOVEMENTS_INVENTORY_STOCK,
      data,
      responseTypeV
    );
  }

  static reportInventoryInMovement(data) {
    return axios.post(
      ServicePath.EXCEL_MOVEMENTS_INVENTORY_STOCK,
      data,
      responseTypeV
    ); //return axios.post( ServicePath.EXCEL_MOVEMENTS_INVENTORY_MOVEMENT, data );
  }

  static reportEmployees(data) {
    return axios
      .post(ServicePath.EXCEL_EMPLOYEES, data, responseTypeV)
      .then((response) => {
        this.download(response, "Personal.xlsx");
      });
  }
  static reportDepartments(data) {
    return axios
      .post(ServicePath.EXCEL_DEPARTMENTS, data, responseTypeV)
      .then((response) => {
        this.download(response, "Departamentos.xlsx");
      });
  }
  static reportPositions(data) {
    return axios
      .post(ServicePath.EXCEL_POSITIONS, data, responseTypeV)
      .then((response) => {
        this.download(response, "Cargos.xlsx");
      });
  }

  static reportRemissionRequest(data) {
    return axios
      .post(ServicePath.EXCEL_ENTRIES_BY_SUPPLIER, data, responseTypeV)
      .then((response) => {
        this.download(response, "EntradasRecepciones.xlsx");
      });
  }
  static reportShipmentAndProduct(data) {
    return axios.post(
      ServicePath.EXCEL_SHIPMENTS_AND_PRODUCTS,
      data,
      responseTypeV
    );
  }

  static reportReception(data) {
    return axios.post(ServicePath.EXCEL_RECEPTION, data, responseTypeV);
  }

  static reportExpiredStock(data) {
    return axios.post(ServicePath.EXCEL_EXPIRED_STOCK, data);
  }

  static reportMedicalPrescriptionPercentage(data) {
    return axios.post(
      ServicePath.EXCEL_STOCK_MEDICAL_PRESCRIPTION_PERCENTAGE,
      data,
      responseTypeV
    );
  }

  static reportOwncloud(data) {
    return instance.post(REPORT_AUDIT, data, responseTypeV);
  }

  static reportStockWarehouse(data){
    return axios.post(EXCEL_STOCK_WAREHOUSE, data, responseTypeV);
  }

  static reportStockMovement(data){
    return axios.post(EXCEL_STOCK_MOVEMENT, data, responseTypeV);
  }

  static reportOutputIncident(data){
    return axios.post(EXCEL_OUTPUT_INCIDENT, data, responseTypeV);
  }

  static reportOutputConsumptionKey(data){
    return axios.post(EXCEL_OUTPUT_CONSUMPTION_KEY, data, responseTypeV);
  }

  static reportAuditOutputOrder(data){
    return axios.post(EXCEL_AUDIT_OUTPUT, data, responseTypeV);
  }

  static reportPharmacyJobTime(data){
    return axios.post(EXCEL_PHATMACY_JOB_TIME, data, responseTypeV);
  }
}
