export default class PermissionConstants {
  /* Permisos de Generales */
  static GENERAL = "GENERAL";
  static INCOMING_OUTGOING = "INCOMING-OUTGOING";

  /* Permisos de Gestionar perfil */
  static PROFILE_MANAGEMENT = "PROFILE-MANAGEMENT";
  static PRO_UPD_PASS_01 = "PRO-UPD-PASS-01";
  static PRO_UPD_EMAIL_02 = "PRO-UPD-EMAIL-02";

  /* Permisos de Tablero de Control */
  static DASHBOARD = "DASHBOARD";
  static DAS_SUP_01 = "DAS-SUP-01"; // Dashboard Abasto
  static DAS_ACC_02 = "DAS-ACC-02"; // Dashboard Acreditación
  static DAS_GEN_REP_03 = "DAS-GEN-REP-03"; // Dashboard Reporte General
  static DAS_SUP_REP_04 = "DAS-SUP-REP-04"; // Dashboard Reporte de Suministros
  static DAS_STA_05 = "DAS-STA-05"; // Dashboard Estadística
  static DAS_PRO_STO_06 = "DAS-PRO-STO-06"; // Dashboard Existencias de Producto

  /* Permisos de Trazabilidad */
  static TRACEABILITY = "TRACEABILITY";

  static TRA_KAR = "TRA-KAR";
  static TRA_KAR_PRO_LOT = "TRA-KAR-PRO-LOT";
  static TRA_KAR_PRO_STK = "TRA-KAR-PRO-STK";

  static TRA_AUD = "TRA-AUD";
  static TRA_AUD_OUT_SEQ = "TRA-AUD-OUT-SEQ";

  static TRA_REM = "TRA-REM";
  static TRA_REM_SUP_WARE = "TRA-REM-SUP-WARE";
  static TRA_REM_TRA_WARE = "TRA-REM-TRA-WARE";

  static TRA_WARE = "TRA-WARE";
  static TRA_WARE_STK_ALL = "TRA-WARE-STK-ALL";
  static TRA_WARE_STK_PHA = "TRA-WARE-STK-PHA";
  static TRA_WARE_STK_PHA_SIN = "TRA-WARE-STK-PHA-SIN";
  static TRA_WARE_STK_PHA_JUR = "TRA-WARE-STK-PHA-JUR";
  static TRA_WARE_STK_PHA_ALL = "TRA-WARE-STK-PHA-ALL";
  static TRA_WARE_STK_MOV = "TRA-WARE-STK-MOV";
  static TRA_WARE_STK_PHA_MOV = "TRA-WARE-STK-PHA-MOV";

  static TRA_OUT = "TRA-OUT";
  static TRA_OUT_SHP = "TRA-OUT-SHP";
  static TRA_OUT_SHP_INV = "TRA-OUT-SHP-INV";
  static TRA_OUT_SHP_ALL = "TRA-OUT-SHP-ALL";
  static TRA_OUT_CON = "TRA-OUT-CON";
  static TRA_OUT_INC = "TRA-OUT-INC";

  static TRA_PHA = "TRA-PHA";
  static TRA_PHA_JOB_TIME = "TRA-PHA-JOB-TIME";
  static TRA_PHA_DIS_PERC = "TRA_PHA_DIS_PERC";


  static TRA_CON_REP_03 = "TRA-CON-REP-03";
  static TRA_CYC_INV_07 = "TRA-CYC-INV-07";
  static TRA_STK_08_1 = "TRA-STK-08-1";
  static TRA_STK_08_2 = "TRA-STK-08-2";
  static TRA_STA_ORD_09 = "TRA-STA-ORD-09";
  static TRA_REC_10 = "TRA-REC-10";
  static TRA_PER_DIS_12 = "TRA-PER-DIS-12";
  static TRA_DIS_INT_13 = "TRA-DIS-INT-13";
  static TRA_DIS_INT_14 = "TRA-DIS-INT-14";
  static TRA_DIS_INT_15 = "TRA-DIS-INT-15";
  static TRA_WAR_INV_06 = "TRA-WAR-INV-06";


  /* Permisos de Auditoria */

  static AUDIENCE = "AUDIENCE";
  static AUD_REM_LIST_01 = "AUD-REM-LIST-01";
  static AUD_REM_DL_PDF_02 = "AUD-REM-DL-PDF-02";
  static AUD_REM_OW_PDF_03 = "AUD-REM-OW-PDF-03";

  static AUD_SHIP_LIST_01 = "AUD-SHIP-LIST-01";
  static AUD_SHIP_DL_PDF_02 = "AUD-SHIP-DL-PDF-02";
  static AUD_SHIP_OW_PDF_03 = "AUD-SHIP-OW-PDF-03";


  /* Permisos de Entradas */
  static REMISSION = "REMISSION";
  static REQUISITION = "REQUISITION"; // Pedidos - Listar pedidos
  static REM_REQ_ADD_01 = "REM-REQ-ADD-01";
  static REM_REQ_EDIT_02 = "REM-REQ-EDIT-02";
  static REM_REQ_DL_XLSX_03 = "REM-REQ-DL-XLSX-03";

  static REMISSION_BY_SUPPLIER = "REMISSION-BY-SUPPLIER"; // Entradas por proveedor - Listar entradas por proveedor
  static REM_SUPP_ADD_01 = "REM-SUPP-ADD-01";
  static REM_SUPP_DETA_02 = "REM-SUPP-DETA-02";
  static REM_SUPP_EDIT_03 = "REM-SUPP-EDIT-03"; // Modficar información de entrada
  static REM_SUPP_CHA_REM_04 = "REM-SUPP-CHA-REM-04";
  static REM_SUPP_DL_PDF_05 = "REM-SUPP-DL-PDF-05";
  static REM_SUPP_DL_XLSX_06 = "REM-SUPP-DL-XLSX-06";
  static REM_SUPP_ADD_PROD_REM_07 = "REM-SUPP-ADD-PROD-REM-07"; // Agregar productos a la entrada
  static REM_SUPP_EDIT_PROD_REM_08 = "REM-SUPP-EDIT-PROD-REM-08";

  static REMISSION_BY_TRANSFER = "REMISSION-BY-TRANSFER"; // Entradas por traspaso - Listar entradas por traspaso
  static REM_TRAN_DETA_01 = "REM-TRAN-DETA-01";
  static REM_TRAN_ADD_PROD_02 = "REM-TRAN-ADD-PROD-02";
  static REM_TRAN_DL_PDF_03 = "REM-TRAN-DL-PDF-03";
  static REM_TRAN_DL_XLSX_04 = "REM-TRAN-DL-XLSX-04";
  static REM_TRAN_EDIT_PROD_05 = "REM-TRAN-EDIT-PROD-05";

  /* Permisos de Almacen */
  static WAREHOUSE = "WAREHOUSE";
  static INVENTORY_DETAIL = "INVENTORY-DETAIL"; // Detalle de Inventario - Listar detalle inventario y productos
  static WAR_INV_PRO_TRA_01 = "WAR-INV-PRO-TRA-01";
  static WAR_INV_EDIT_02 = "WAR-INV-EDIT-02";
  static WAR_REA_PRO_03 = "WAR-REA-PRO-03";
  static WAR_INV_QUAN_EDIT_04 = "WAR-INV-QUAN-EDIT-04";
  static WAR_INV_ASS_TAG_05 = "WAR-INV-ASS-TAG-05"; // Asignar tags
  static WAR_INV_ASS_TAG_06 = "WAR-INV-ASS-TAG-06"; // Asignar tags
  
  // Detalle de Inventario producto no conforme- Listar detalle inventario producto no conforme
  static INVENTORY_NON_COMPLIANT_PRODUCT = "INVENTORY-NON-COMPLIANT-PRODUCT"; 
  static WAR_INV_NCP_PRO_TRA_01 = "WAR-INV-PRO-TRA-01";

  static INVENTORY_DETAIL_EXPIRED = "INVENTORY-DETAIL-EXPIRED"; // Detalle de Inventario Caduco - Listar detalle inventario y productos caduco
  static WAR_INV_EXP_PRO_TRA_01 = "WAR-INV-EXP-PRO-TRA-01";
  static WAR_INV_EXP_EDIT_02 = "WAR-INV-EXP-EDIT-02";
  static WAR_EXP_REA_PRO_03 = "WAR-EXP-REA-PRO-03";
  static WAR_INV_EXP_QUAN_EDIT_04 = "WAR-INV-EXP-QUAN-EDIT-04";
  static WAR_INV_EXP_ASS_TAG_05 = "WAR-INV-EXP-ASS-TAG-05"
  static WAR_INV_EXP_ASS_TAG_06 = "WAR-INV-EXP-ASS-TAG-06"

  static CYCLIC_INVENTORY = "CYCLIC-INVENTORY"; // Inventario ciclico - Listar inventario ciclico
  static WAR_INV_CYC_ADD_COM_01 = "WAR-INV-CYC-ADD-COM-01";


  /* Permisos de Solicitudes */
  static PRE_REQUEST = "PRE-REQUEST"
  static PRE_REQUEST_APPLICATION = "PRE-REQUEST-APPLICATION"
  static PRE_REQUEST_APPLICATION_ADD_01 = "PRE-REQUEST-APPLICATION-ADD-01"
  static PRE_REQUEST_APPLICATION_DETA_02 = "PRE-REQUEST-APPLICATION-DETA-02"
  static PRE_REQUEST_APPLICATION_STATUS_03 = "PRE-REQUEST-APPLICATION-STATUS-03"
  static PRE_REQUEST_APPLICATION_DL_PDF_04 = "PRE-REQUEST-APPLICATION-DL-PDF-04"

  static PRE_REQUEST_VALIDATOR = "PRE-REQUEST-VALIDATOR"
  static PRE_REQUEST_VALIDATOR_EDIT_STATUS_01 = "PRE-REQUEST-VALIDATOR-EDIT-STATUS-01"
  static PRE_REQUEST_VALIDATOR_ADD_02 = "PRE-REQUEST-VALIDATOR-ADD-02"
  static PRE_REQUEST_VALIDATOR_DL_PDF_03 = "PRE-REQUEST-VALIDATOR-DL-PDF-03"

  static PRE_REQUEST_AUTHORIZER = "PRE-REQUEST-AUTHORIZER"
  static PRE_REQUEST_AUTHORIZER_EDIT_STATUS_01 = "PRE-REQUEST-AUTHORIZER-EDIT-STATUS-01"
  static PRE_REQUEST_AUTHORIZER_DL_PDF_02 = "PRE-REQUEST-AUTHORIZER-DL-PDF-02"

  /* Permisos de Salidas */
  static STARTING_ORDER = "STARTING-ORDER";
  static STARTING_ORDER_GENERAL = "STARTING-ORDER-GENERAL"; // Salida general - Listar salida general
  static ORD_GEN_DETA_01 = "ORD-GEN-DETA-01";
  static ORD_GRN_DL_PDF_02 = "ORD-GRN-DL-PDF-02";
  static ORD_GRN_EDIT_03 = "ORD-GRN-EDIT-03";
  static ORD_GEN_ADD_04 = "ORD-GEN-ADD-04";
  static ORD_GEN_DL_XLSX_05 = "ORD-GEN-DL-XLSX-05";

  static STARTING_ORDER_BY_REASON = "STARTING-ORDER-BY-REASON"; // Salidas con motivo - Listar salidas con motivo
  static ORD_REA_DETA_01 = "ORD-REA-DETA-01";
  static ORD_REA_EDIT_02 = "ORD-REA-EDIT-02";
  static ORD_REA_ADD_03 = "ORD-REA-ADD-03";
  static ORD_REA_DL_PFD_04 = "ORD-REA-DL-PFD-04";
  static ORD_REA_DL_XLSX_05 = "ORD-REA-DL-XLSX-05";

  static SHIPMENT_REQUESTS = "SHIPMENT-REQUESTS"; // Solicitudes - Listar solicitudes
  static ORD_SHIP_REQ_ADD_01 = "ORD-SHIP-REQ-ADD-01";
  static ORD_SHIP_REQ_EDIT_02 = "ORD-SHIP-REQ-EDIT-02";
  static ORD_SHIP_REQ_AUTH_03 = "ORD-SHIP-REQ-AUTH-03";
  static ORD_SHIP_REQ_DL_PDF_04 = "ORD-SHIP-REQ-DL-PDF-04";

  /* Permisos de Recolección */
  static PICKING = "PICKING";
  static PICKING_DETAIL = "PICKING-DETAIL";
  static PIC_PIC_DETA_LIST_01 = "PIC-PIC-DETA-LIST-01"; // Recolección - Listar recolección
  static PIC_PIC_END_02 = "PIC-PIC-END-02";

  static PIC_PIC_VALI_LIST_01 = "PIC-PIC-VALI-LIST-01"; // Validación de Recolección - Listar validación de recolección
  static PIC_PIC_VALI_END_02 = "PIC-PIC-VALI-END-02";

  static PIC_PIC_HIS_LIST_01 = "PIC-PIC-HIS-LIST-01"; // Historico de Recolección - Listar historico de recolección
  static PIC_PIC_DL_PDF_02 = "PIC-PIC-DL-PDF-02";
  static PIC_PIC_HIS_DETA_03 = "PIC-PIC-HIS-DETA-03";
  static PIC_PIC_DL_XLSX_04 = "PIC-PIC-DL-XLSX-04";

  static PICKING_MOVEMENTS = "PICKING-MOVEMENTS"; // Movimientos de recolección - Listar movimientos de recolección
  static PICK_MOV_DL_XLSX_01 = "PICK-MOV-DL-XLSX-01";

  /* Permisos de Envíos */
  static SHIPMENTS = "SHIPMENTS";
  static SHIPMENT_DETAIL = "SHIPMENT-DETAIL"; // Detalle de Envíos - Listar envíos pendientes de traslado
  static SHIP_SHIP_01 = "SHIP-SHIP-01";
  static SHIP_SHIP_DL_PDF_02 = "SHIP-SHIP-DL-PDF-02";
  static SHIP_SHIP_DL_XLSX_03 = "SHIP-SHIP-DL-XLSX-03";

  static HISTORY_OF_SHIPMENTS = "HISTORY-OF-SHIPMENTS"; // Histórico de Envíos - Listar histórico de envíos
  static SHIP_HIST_DETA_01 = "SHIP-HIST-DETA-01";
  static SHIP_HIST_DL_PDF_02 = "SHIP-HIST-DL-PDF-02";
  static SHIP_HIST_DL_XLSX_03 = "SHIP-HIST-DL-XLSX-03";

  /* Permisos de Recepción */
  static RECEPTION = "RECEPTION";
  static PENDING = "PENDING"; // Pendientes - Listar recepciones pendientes de traslado
  static REC_PEN_SHIP_01 = "REC-PEN-SHIP-01";
  static REC_PEN_DL_XLSX_02 = "REC-PEN-DL-XLSX-02";

  static HISTORICAL = "HISTORICAL"; // Histórico - Listar histórico de recepciones
  static REC_HIS_REC_01 = "REC-HIS-REC-01";
  static REC_HIS_DL_PDF_02 = "REC-HIS-DL-PDF-02";
  static REC_HIS_DL_XLSX_03 = "REC-HIS-DL-XLSX-03";

  /* Permisos de Incidencias */
  static INCIDENTS = "INCIDENTS";
  static PERFORMED = "PERFORMED"; // Realizadas - Listar incidencias realizadas
  static INC_PER_ADD_01 = "INC-PER-ADD-01";
  static INC_PER_CONF_02 = "INC-PER-CONF-02";
  static INC_PER_DETA_03 = "INC-PER-DETA-03";

  static STARTERS = "STARTERS"; // Entrantes - Listar incidencias entrantes
  static INC_STA_DETA_01 = "INC-STA-DETA-01";

  /* Permisos de Dispensación */
  static DISPENSING = "DISPENSING";
  static PRESCRIPTIONS_DISPENSING = "PRESCRIPTIONS-DISPENSING"; // Dispensar receta - Listar dispensar receta
  static DIS_PRE_ADD_01 = "DIS-PRE-ADD-01";
  static DIS_PRE_EDIT_02 = "DIS-PRE-EDIT-02";
  static DIS_PRE_DL_PDF_03 = "DIS-PRE-DL-PDF-03";
  static DIS_PRE_DL_PRE_DIS_04 = "DIS-PRE-DL-PRE-DIS-04";
  static DIS_PRE_DL_PRE_DATE_05 = "DIS-PRE-DL-PRE-DATE-05";
  static DIS_PRE_ADD_06 = "DIS-PRE-ADD-06";

  static PRESCRIPTIONS_INTRAHOSPITAL_DISPENSING = "PRESCRIPTIONS-INTRAHOSPITAL-DISPENSING"; // Dispensar receta intrahospitalaria - Listar dispensar receta intrahospitalaria
  static DIS_PRE_INT_ADD_01 = "DIS-PRE-INT-ADD-01";
  static DIS_PRE_INT_EDIT_02 = "DIS-PRE-INT-EDIT-02";
  static DIS_PRE_INT_DL_PDF_03 = "DIS-PRE-INT-DL-PDF-03";
  static DIS_PRE_INT_DL_PRE_DIS_04 = "DIS-PRE-INT-DL-PRE-DIS-04";
  static DIS_PRE_INT_DL_PRE_DATE_05 = "DIS-PRE-INT-DL-PRE-DATE-05";

  static DOCTORS = "DOCTORS"; // Doctores - Listar doctores
  static DIS_DOC_ADD_01 = "DIS-DOC-ADD-01";
  static DIS_DOC_EDIT_02 = "DIS-DOC-EDIT-02";
  static DIS_DOC_DL_XLSX_03 = "DIS-DOC-DL-XLSX-03";

  static SURVEYS = "SURVEYS";
  static DIS_SURVEYS_DEMO = "DIS-SURVEYS-DEMO"; // Encuestas DEMO

  static STAFF = "STAFF"; // Personal - Listar personal
  static DIS_STA_ADD_01 = "DIS-STA-ADD-01";
  static DIS_STA_EDIT_02 = "DIS-STA-EDIT-02";

  /* Permisos de Cancelaciones */
  static CANCELLATIONS = "CANCELLATIONS";
  static CAN_ORD_LIST_01 = "CAN-ORD-LIST-01"; // Salidas/Salida con motivo - Listar salidas
  static CAN_ORD_CAN_02 = "CAN-ORD-CAN-02";

  // static CAN_ORD_REA_LIST_01 = "CAN-ORD-REA-LIST-01"; // Salida con motivo - Listar salida con motivo
  // static CAN_ORD_REA_CAN_02 = "CAN-ORD-REA-CAN-02";

  static CAN_ORD_DIS_LIST_01 = "CAN-ORD-DIS-LIST-01"; // Dispensación - Listar dispensación
  static CAN_ORD_DIS_CAN_02 = "CAN-ORD-DIS-CAN-02";

  /* Permisos de Administración */
  static ADMINISTRATION = "ADMINISTRATION";
  static USERS = "USERS"; // Usuarios - Listar usuarios
  static ADM_USE_ADD_01 = "ADM-USE-ADD-01";
  static ADM_USE_DETA_02 = "ADM-USE-DETA-02";
  static ADM_USE_EDIT_03 = "ADM-USE-EDIT-03";
  static ADM_USE_RES_04 = "ADM-USE-RES-04";
  static ADM_USE_EDIT_STA_05 = "ADM-USE-EDIT-STA-05";
  static ADM_USE_DL_XLSX_06 = "ADM-USE-DL-XLSX-06";
  static ADM_USE_EDIT_BRANCH_07 = "ADM-USE-EDIT-BRANCH-07";

  static ROLES = "ROLES"; // Roles - Listar roles
  static ADM_ROL_ADD_01 = "ADM-ROL-ADD-01";
  static ADM_ROL_EDIT_02 = "ADM-ROL-EDIT-02";
  static ADM_ROL_COPY_03 = "ADM-ROL-COPY-03";

  /* Permisos de Catálogos */
  static CATALOGS = "CATALOGS";
  static POSITIONS = "POSITIONS"; // Cargos - Listar cargos
  static CAT_POS_ADD_01 = "CAT-POS-ADD-01";
  static CAT_POS_EDIT_02 = "CAT-POS-EDIT-02";
  static CAT_POS_DL_XLSX_03 = "CAT-POS-DL-XLSX-03";

  static CATEGORIES = "CATEGORIES"; // Categorías - Listar categorías
  static CAT_CAT_ADD_01 = "CAT-CAT-ADD-01";
  static CAT_CAT_EDIT_02 = "CAT-CAT-EDIT-02";
  static CAT_CAT_DL_XLSX_03 = "CAT-CAT-DL-XLSX-03";

  static DEPARTMENTS = "DEPARTMENTS"; // Departamentos - Listar departamentos
  static CAT_DEP_ADD_01 = "CAT-DEP-ADD-01";
  static CAT_DEP_EDIT_02 = "CAT-DEP-EDIT-02";
  static CAT_DEP_DL_XLSX_03 = "CAT-DEP-DL-XLSX-03";

  static FINANCING = "FINANCING"; // Financiamiento - Listar financiamiento
  static CAT_FIN_ADD_01 = "CAT-FIN-ADD-01";
  static CAT_FIN_EDIT_02 = "CAT-FIN-EDIT-02";
  static CAT_FIN_DL_XLSX_03 = "CAT-FIN-DL-XLSX-03";

  // Jurisdicciones - Listar jurisdicciones
  // static JURISDICTIONS = "JURISDICTIONS"; 
  // static CAT_JUR_ADD_01 = "CAT-JUR-ADD-01";
  // static CAT_JUR_EDIT_02 = "CAT-JUR-EDIT-02";
  // static CAT_JUR_DL_XLSX_03 = "ROLCAT-JUR-DL-XLSX-03ES";

  static PRODUCTS = "PRODUCTS"; // Productos - Listar productos
  static CAT_PRO_ADD_01 = "CAT-PRO-ADD-01";
  static CAT_PRO_EDIT_02 = "CAT-PRO-EDIT-02";
  static CAT_PRO_DL_XLSX_03 = "CAT-PRO-DL-XLSX-03";

  static SUPPLIERS = "SUPPLIERS"; // Proveedores - Listar proveedores
  static CAT_SUPP_ADD_01 = "CAT-SUPP-ADD-01";
  static CAT_SUPP_EDIT_02 = "CAT-SUPP-EDIT-02";
  static CAT_SUPP_DL_XLSX_03 = "CAT-SUPP-DL-XLSX-03";

  static DISTRIBUTION_BOARD = "DISTRIBUTION-BOARD"; // Cuadro de Distribución - Listar cuadro de distribución
  static DIS_BOA_ADD_01 = "DIS-BOA-ADD-01";
  static DIS_BOA_EDIT_02 = "DIS-BOA-EDIT-02";
  static DIS_BOA_PROD_EDIT_03 = "DIS-BOA-PROD-EDIT-03";
  static DIS_BOA_BRAN_EDIT_04 = "DIS-BOA-BRAN-EDIT-04";

  static BRANCH_OFFICES = "BRANCH-OFFICES"; // Sucursales - Listar sucursales
  static CAT_BRAN_ADD_01 = "CAT-BRAN-ADD-01";
  static CAT_BRAN_EDIT_02 = "CAT-BRAN-EDIT-02";
  static CAT_BRAN_STA_03 = "CAT-BRAN-STA-03";
  static CAT_BRAN_STA_ADV_05 = "CAT-BRAN-STA-ADV-05";
  static CAT_BRAN_DL_XLSX_04 = "CAT-BRAN-DL-XLSX-04";

  /* Permisos de Configuración */
  static CONFIGURATION = "CONFIGURATION"; 
  static CONFIGURATION_DASHBOARD_ABASTO = "CONFIGURATION-DASHBOARD-ABASTO"; // Configuración del tablero de control Abasto 
  static COF_EDIT_ABASTO_STATUS_01 = "COF-EDIT-ABASTO-STATUS-01";
  static COF_EDIT_ABASTO_LEVEL_02 = "COF-EDIT-ABASTO-LEVEL-02";

  static CONFIGURATION_DASHBOARD_STATISTIC = "CONFIGURATION-DASHBOARD-STATISTIC"; // Configuración del tablero de control Abasto 
  static COF_EDIT_STATISTIC_STATUS_01 = "COF-EDIT-STATISTIC-STATUS-01";
  
  static CONFIGURATION_ABASTO = "CONFIGURATION-ABASTO";

  /* Permisos Producto no conforme */
  static NON_COMPLIANT_PRODUCT = "NON-COMPLIANT-PRODUCT"; // Producto no conforme - Listar Producto no conforme
  static NCP_ADD_01 = "NCP-ADD-01";
  static NCP_DETA_02 = "NCP-DETA-02";
  static NCP_RET_03 = "NCP-RET-03";
}
