/**
 * @author Yair Alvarez
 * @company Erillam Healthcare
 * @version 1.0
 */
import { UtilFront } from "@/core";
import { instance } from "@/core/ApiConfig.js";

// Set endpoints 
const BRANCH_OFFICE_ADD = "brofsvc/brof01";
const GET_ALL_BRANCH_OFFICE_ACTIVE = "brofsvc/brof02";
const BRANCH_OFFICE_UPDATE = "brofsvc/brof03";
const BRANCH_OFFICE_LIST_ALL_BY_USER = "brofsvc/brof07";
const BRANCH_OFFICE_LIST_ALL_SUPPLIER = "splsvc/spl07";
const BRANCH_OFFICE_LIST_ALL = "brofsvc/brof08";
const BRANCH_OFFICE_LIST_ALL_TYPES = "brofsvc/brof09";
const BRANCH_OFFICE_GET = "brofsvc/brof10";
const BRANCH_OFFICE_UPDATE_STATUS = "brofsvc/brof11";
const BRANCH_OFFICE_BY_TYPE = "brofsvc/brof13";
const BRANCH_OFFICE_TYPE_LIST = "branch-office-types"
const BRANCH_OFFICE_STATUS_ADVANCED = "brofsvc/advanced-status"
const BRANCH_OFFICE_GET_SUPPLIERS = "brofsvc/branch-office-suppliers"

const BRANCH_OFFICE_LIST_CANCELATION = "brofsvc/branch-offices-orders"


export default class BranchOfficeRequest {

  static listBranchOfficeUsers(data) {
    return instance.post(BRANCH_OFFICE_LIST_CANCELATION, data);
  }

  static listAllActive() {
    return instance.post(GET_ALL_BRANCH_OFFICE_ACTIVE);
  }

  static listAllTypeBrachOffice() {
    return instance.get(BRANCH_OFFICE_TYPE_LIST);
  }

  static listAllBranchSuppliers() {
    return instance.get(BRANCH_OFFICE_GET_SUPPLIERS);
  }

  static updateStatusAdvanced(payload) {
    return instance.put(BRANCH_OFFICE_STATUS_ADVANCED, payload);
  }

  static listAllSupplier() {
    return instance.get(BRANCH_OFFICE_LIST_ALL_SUPPLIER);
  }

  static listAll() {
    return instance.post(BRANCH_OFFICE_LIST_ALL);
  }

  static addBranchOffice(object) {
    return instance.post(BRANCH_OFFICE_ADD, object);
  }

  static updateBranchOffice(payload) {
    return instance.post(BRANCH_OFFICE_UPDATE, payload);
  }

  static listAllTypes() {
    return instance.post(BRANCH_OFFICE_LIST_ALL_TYPES);
  }

  static getBranchUpdate(payload) {
    return instance.post(BRANCH_OFFICE_GET, payload);
  }

  static updateStatus(object) {
    return instance.post(BRANCH_OFFICE_UPDATE_STATUS, object);
  }
  static listAllByUser(object) {
    return instance.post(BRANCH_OFFICE_LIST_ALL_BY_USER, object);
  }

  static listAllKey(id) {
    let branchOfficeArray = [];
    return new Promise((resolve, reject) => {
      this.listAllStatusActive()
        .then((response) => {
          for (var [index, value] of Object.entries(response.data.data)) {
            if (id != value.idBranchoffice) {
              let branchOfficeItem = {
                text: value.name + " | " + value.branchOfficeKey,
                value: value.idBranchoffice,
              };
              branchOfficeArray.push(branchOfficeItem);
            }
          }

          resolve(branchOfficeArray);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static listAllByType(sendData) {
    return instance.post(BRANCH_OFFICE_BY_TYPE, sendData);
  }

  static listAllByTypeKey() {
    let branchOfficeArray = [];
    this.listAllByType({ branchOfficeTypeId: 3 } ).then((response) => {
      let data = response.data.data;
      if (data.success) {
        for (var [index, value] of Object.entries(data.data)) {
          let branchOfficeItem = {
            text: value.name + " - (" + value.branchOfficeKey + ")",
            value: value.idBranchOffice,
          };
          branchOfficeArray.push(branchOfficeItem);
        }
      }
    });
    return branchOfficeArray;
  }
}
